import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout/index"
import SEO from "../components/seo"
import Hero from "../components/hero/index"
import Benefits from "../components/benefits/index"
import Products from "../components/products/index"

const HomePageEN = ({ location, data: { allPrismicHomepage } }) => {
  const pageData = allPrismicHomepage.edges[0].node.data

  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="en-US"
      />
      <Hero />
      <Benefits data={pageData} location={location} />
      <Products location={location} />
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicHomepage(filter: { lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            title_left_side {
              text
            }
            text_left_side {
              raw
            }
            title_right_side {
              text
            }
            text_right_side {
              raw
            }
          }
        }
      }
    }
  }
`

export default HomePageEN
